.property-input-container {
    display: flex;
    width: 100%;
}

.property-input-title-container {
    margin-top: auto;
    margin-bottom: auto;
    width: 25%;
}

.property-input-value-conainer {
    display: flex;
    width: 75%;
}