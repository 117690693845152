.docking-panel-title-button {
    padding: 0 !important;
    margin: 3px 0 0 2px !important;
    width: 20px !important;
    height: 20px !important;
    z-index: 2 !important;
}

.docking-panel-title-button-icon {
    padding: 0 !important;
}

.docking-panel-title-maximize-icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px;
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22currentColor%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20class%3D%22feather%20feather-maximize-2%22%3E%3Cpolyline%20points%3D%2215%203%2021%203%2021%209%22%3E%3C%2Fpolyline%3E%3Cpolyline%20points%3D%229%2021%203%2021%203%2015%22%3E%3C%2Fpolyline%3E%3Cline%20x1%3D%2221%22%20y1%3D%223%22%20x2%3D%2214%22%20y2%3D%2210%22%3E%3C%2Fline%3E%3Cline%20x1%3D%223%22%20y1%3D%2221%22%20x2%3D%2210%22%20y2%3D%2214%22%3E%3C%2Fline%3E%3C%2Fsvg%3E)
}

.docking-panel-title-minimize-icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px;
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22currentColor%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20class%3D%22feather%20feather-minimize-2%22%3E%3Cpolyline%20points%3D%224%2014%2010%2014%2010%2020%22%3E%3C%2Fpolyline%3E%3Cpolyline%20points%3D%2220%2010%2014%2010%2014%204%22%3E%3C%2Fpolyline%3E%3Cline%20x1%3D%2214%22%20y1%3D%2210%22%20x2%3D%2221%22%20y2%3D%223%22%3E%3C%2Fline%3E%3Cline%20x1%3D%223%22%20y1%3D%2221%22%20x2%3D%2210%22%20y2%3D%2214%22%3E%3C%2Fline%3E%3C%2Fsvg%3E)
}

.docking-panel-title-refresh-button {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px;
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M22%2C13.81l-.22-9.6L18.91%2C6.28A9%2C9%2C0%2C0%2C0%2C3.67%2C8.61l3.8%2C1.3A5%2C5%2C0%2C0%2C1%2C12%2C7a4.94%2C4.94%2C0%2C0%2C1%2C3.68%2C1.65l-2.82%2C2.06ZM16.42%2C8A5.94%2C5.94%2C0%2C0%2C0%2C7%2C8.7L5.06%2C8A8%2C8%2C0%2C0%2C1%2C18.14%2C6.92l.6.72%2C2-1.48.15%2C6.24-5.92-2L17.17%2C8.8Z%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M16.53%2C14.08A5%2C5%2C0%2C0%2C1%2C8.3%2C15.35l2.84-2.08-9.09-3.1.22%2C9.6%2C2.79-2a9%2C9%2C0%2C0%2C0%2C15.28-2.35ZM12%2C20a8%2C8%2C0%2C0%2C1-6.17-2.91l-.6-.73-2%2C1.47-.15-6.25L9%2C13.6%2C6.82%2C15.2l.74.82A6%2C6%2C0%2C0%2C0%2C17%2C15.29L19%2C16A8%2C8%2C0%2C0%2C1%2C12%2C20Z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
}

.docking-panel-title-download-bom-button {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px;
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M15%2C8V2H9V8H6l6.1%2C8L18%2C8Zm-2.92%2C6.33L8%2C9h2V3h4V9h2Z%22%20fill-rule%3D%22evenodd%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M17%2C16v2H7V16H3v6H21V16Zm3%2C5H4V17H6v2H18V17h2Z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
}

.parameters-pane {
    padding-left: 15px;
    padding-right: 15px;
    overflow-y: auto;
    overflow-x: hidden;
}