.custom-component-publish-control-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    height: 36px;
}

.custom-component-publish-control-button-container {
    flex-flow: row;
    align-self: flex-end;
}