.custom-panel-component-card {
    all: unset;
    height: 150px;
    width: 100%;
    border: black solid 2px;
    border-radius: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    transition: border-color 0.2s ease;
}

.custom-panel-component-card:hover {
    border: blue solid 2px;
}

.custom-panel-component-card--disabled {
    opacity: 0.5;
    pointer-events: none;
}

.custom-panel-component-card-thumbnail-container {
    width: 150px;
}

.custom-panel-component-card-thumbnail {
    width: 100%;
    height: 100%;
    padding: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 130px;
    box-sizing: border-box;
}

.custom-panel-component-card-data-container {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    justify-content: space-evenly;
}

.custom-panel-component-card-data-title {
    font-weight: 600;
    font-size: 24px;
}

.custom-panel-component-card-stats-container {
    width: 26px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.custom-panel-component-card-stats-custom-panel-type {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M11%2C11H3V3h8ZM4%2C4v6h6V4Z%22/%3E%3Cpath%20d%3D%22M21%2C11H13V3h8ZM14%2C4v6h6V4Z%22/%3E%3Cpath%20d%3D%22M11%2C21H3V13h8ZM4%2C14v6h6V14Z%22/%3E%3Cpath%20d%3D%22M21%2C21H13V13h8Zm-7-7v6h6V14Z%22/%3E%3C/svg%3E);
    width: 24px;
    height: 24px;
}

.custom-panel-component-card-stats-custom-corner-type {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cg%3E%3Cpath%20id%3D%22svg_1%22%20d%3D%22m20.62833%2C5.99417l-8%2C-3.7a1%2C1%200%200%200%20-0.84%2C0l-8%2C3.7a1%2C1%200%200%200%20-0.58%2C0.9l0%2C8.91a1%2C1%200%200%200%200.47%2C0.84l8%2C5a1%2C1%200%200%200%201.06%2C0l8%2C-5a1%2C1%200%200%200%200.47%2C-0.84l0%2C-8.91a1%2C1%200%200%200%20-0.58%2C-0.9zm-0.42%2C9.67a0.24%2C0.24%200%200%201%20-0.12%2C0.21l-7.75%2C4.82a0.24%2C0.24%200%200%201%20-0.26%2C0l-7.75%2C-4.82a0.24%2C0.24%200%200%201%20-0.12%2C-0.21l0%2C-8.56a0.24%2C0.24%200%200%201%200.15%2C-0.22l7.75%2C-3.63a0.23%2C0.23%200%200%201%200.2%2C0l7.75%2C3.58l0.15%2C8.83z%22/%3E%3Cpath%20id%3D%22svg_2%22%20d%3D%22m18.93%2C7.4l-0.39%2C-0.18a0.08%2C0.08%200%200%200%20-0.09%2C0l-6.45%2C4l-6.45%2C-4a0.07%2C0.07%200%200%200%20-0.09%2C0l-0.39%2C0.18a0.12%2C0.12%200%200%200%20-0.07%2C0.11l0%2C0.49a0.11%2C0.11%200%200%200%200%2C0.08l6.46%2C4l0%2C7a0.11%2C0.11%200%200%200%200%2C0.08l0.4%2C0.24a0.09%2C0.09%200%200%200%200.12%2C0l0.4%2C-0.24a0.11%2C0.11%200%200%200%200%2C-0.08l0%2C-7l6.46%2C-4a0.14%2C0.14%200%200%200%200.16%2C-0.08l0%2C0l0%2C-0.5a0.12%2C0.12%200%200%200%20-0.07%2C-0.1z%22/%3E%3Crect%20fill%3D%22white%22%20id%3D%22svg_4%22%20height%3D%226.45833%22%20width%3D%2221.875%22%20y%3D%220.59375%22%20x%3D%220.95833%22/%3E%3C/g%3E%3C/svg%3E);
    width: 24px;
    height: 24px;
}

.custom-panel-component-card-stats-custom-z-shaped-type {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%22-3%20-1%2030%2026%22%3E%3Cg%20transform%3D%22translate(0%2C%206)%22%3E%3Cpath%20d%3D%22M%200%200%20l%208%204.6188%20l%208%20-4.6188%20l%208%204.6188%20v%2012%20l%20-8%20-4.6188%20l%20-8%204.6188%20l%20-8%20-4.6188%20z%22%20stroke%3D%22black%22%20fill%3D%22none%22%2F%3E%3Cline%20x1%3D%228%22%20y1%3D%224.6188%22%20x2%3D%228%22%20y2%3D%2216.6188%22%20stroke%3D%22black%22%2F%3E%3Cline%20x1%3D%2216%22%20y1%3D%220%22%20x2%3D%2216%22%20y2%3D%2212%22%20stroke%3D%22black%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
    width: 24px;
    height: 24px;
}


.custom-panel-component-card-stats-status-badge-container {
    padding-left: 7px;
}