.custom-component-properties-container {
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
}

.custom-component-properties-content-container {
    width: 100%; 
    height: calc(100% - 36px); 
    overflow-y: auto;
}