.generated-revit-model-container {
    display: flex;
    flex-direction: row;
    margin: 0 17px;
    justify-content: space-between;
}

.generated-revit-model-content-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
}

.generated-revit-model-title-container {
    width: 95px;
}