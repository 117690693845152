body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.viewer-container {
  width: 100%;
  height: 100vh;
}

.fullheight {
  display: flex;
  flex-flow: column;
  height: 100%;
}

:focus-visible {
  outline: none;
}

/* whole page scroll bars */
::-webkit-scrollbar-thumb:hover {
  background-color: #80808080;
  /* color:#808080 80 opacity 50%*/
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #80808032;
  /* color:#808080 32 opacity 20%*/
}

::-webkit-scrollbar {
  width: 5px;
}