.editor-2d-container {
    height: calc(100% - 30px);
}

.editor-2d-content {
    width: 100%;
    height: calc(100% - 14px);
    margin: 2;
}

.editor-2d-menu-row {
    display: flex;
    flex-direction: row;
}

.editor-2d-menu-item {
    font-size: 16px;
    color: #8A8C92;
    font-style: normal;
    font-weight: 700;
    margin-left: 17px;
    user-select: none;
}

.editor-processing-indicator-container {
    display: flex;
    width: 340px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    height: 100%;
}