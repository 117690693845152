.library-components-toolbar {
    display: flex;
    position: relative;
    height: 40px;
    min-height: 40px;
    padding-top: 2px;
    padding-left: 7px;
    -moz-box-pack: justify;
    justify-content: space-between;
    -moz-box-align: stretch;
    align-items: stretch;
    z-index: 9998;
    line-height: 1.6;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
    font-family: ArtifaktElement, sans-serif;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px -4px;
}

.library-components-toolbar-buttons-container {
    
}