.custom-component-plan-viewer-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 50%;
}

.custom-component-front-viewer-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
}

.custom-component-side-viewer-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 50%;
}

.custom-component-3d-viewer-container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%; 
    height: 50%;
}